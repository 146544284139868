import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function Coin ({ color, ...props }) {
  return (
    <Picto viewBox="0 0 81.709 81.617" color={color} {...props}>
      <g transform="translate(-964.78 -158.906)">
        <g transform="translate(964.78 158.906)">
          <path
            d="M1042.384,176.512a33.7,33.7,0,0,0-29.631-17.606h-.31A33.5,33.5,0,0,0,983,176.393l-.306.561-.557.31a33.744,33.744,0,1,0,45.887,45.887l.31-.559.561-.306A33.785,33.785,0,0,0,1042.384,176.512Zm-44,60.393h-.007a30,30,0,1,1,.007,0Zm37.574-25.289-4.6,5.935.684-7.573c.1-1.071.187-2.081.187-3.071a33.907,33.907,0,0,0-33.848-33.745l-10.305.629,5.592-4.36a30.1,30.1,0,0,1,42.295,42.185Z"
            transform="translate(-964.78 -158.906)"
            fill={color}
            shapeRendering="auto"
          />
        </g>
        <g transform="translate(982.749 190.233)">
          <path
            d="M972.949,192.758c0-.961.321-1.419,1.375-1.419h1.144a13.323,13.323,0,0,1,0-3.3h-1.1c-1.053,0-1.421-.506-1.421-1.465s.367-1.467,1.421-1.467h1.6a15.939,15.939,0,0,1,15.532-11.96,23.961,23.961,0,0,1,3.069.231,1.319,1.319,0,0,1,1.146,1.832l-.321,1.236c-.229.917-.779,1.282-1.557,1.146a16.4,16.4,0,0,0-2.428-.229,10.807,10.807,0,0,0-10.583,7.743h11.178c1.146,0,1.557.5,1.375,1.511a1.476,1.476,0,0,1-1.7,1.421H980.142a13.344,13.344,0,0,0,0,3.3h10.675q1.719,0,1.375,1.509a1.423,1.423,0,0,1-1.65,1.375h-9.621a10.905,10.905,0,0,0,10.631,8.064,13.861,13.861,0,0,0,2.7-.229c.964-.183,1.6.136,1.788,1.1l.275,1.236a1.361,1.361,0,0,1-1.146,1.788,17.694,17.694,0,0,1-3.665.411,15.668,15.668,0,0,1-15.578-12.371h-1.6C973.27,194.223,972.949,193.721,972.949,192.758Z"
            transform="translate(-972.949 -173.148)"
            fill={color}
            shapeRendering="auto"
          />
        </g>
      </g>
    </Picto>
  )
}

Coin.propTypes = {
  color: PropTypes.string,
}
