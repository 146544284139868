import React from "react"
import Proptypes from "prop-types"
import styled from "styled-components"

import { fontSizes, modules, useThemeContext } from "../../structure/theme"
import breakpoint from "styled-components-breakpoint"

const Title = styled.div`
  font-size: ${fontSizes(`regular`)};
  font-weight: 900;
  line-height: 16px;
  flex: 1;
  margin-top: 0px;

  ${breakpoint(`small`)`
    margin-top: 20px;
    font-size: ${({ theme }) =>
      theme.modules.information.warranty.rounded ? theme.fonts.sizes.xx_large : theme.fonts.sizes.xxx_large};
    line-height: 24px;
  `}
`

const Description = styled.div`
  max-width: 100%;
  font-size: ${fontSizes(`regular`)};
  flex: 1;

  ${breakpoint(`small`)`
    margin-top: 20px;
    line-height: ${({ theme }) => (theme.modules.information.warranty.rounded ? `20px` : `26px`)};
    font-size: ${({ theme }) =>
      theme.modules.information.warranty.rounded ? theme.fonts.sizes.regular : theme.fonts.sizes.large};
  `}
`

const WarrantyContainer = styled.div`
  padding-left: 15px;
  max-width: 100%;
  text-align: left;

  ${breakpoint(`small`)`
  padding: 0 20px;
  text-align: center;
  `}
`

const WarrantyCard = styled.div`
  background-color: ${modules(`information.warranty.backgroundColor`)};
  color: ${modules(`information.warranty.color`)};
  padding: 16px;
  display: flex;
  height: 100%;

  ${breakpoint(`small`)`
    text-align: center;
    flex-direction: column;
    padding: 20px;  

    ${props =>
      props.theme.modules.information.warranty.rounded &&
      `
      padding: 10px;
      width: 235px;
      height: 235px;
      border-radius: 50%;
      margin: 0 auto;
    `}
  `}
`

const PictoBigContainer = styled.div`
  display: none;

  ${breakpoint(`small`)`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  `}
`

const PictoSmallContainer = styled.div`
  display: flex;
  margin-left: 10px;

  ${breakpoint(`small`)`
  display: none;
  `}
`

const Container = styled.div`
  padding: 5px;
  width: 100%;

  ${breakpoint(`small`)`
    padding: 10px;
    width: 50%;
  `}

  ${breakpoint(`medium`)`
    width: 25%;
  `}
`

export default function Warranty ({ title, description, picto: Picto }) {
  const {
    modules: {
      information: {
        warranty: { color },
      },
    },
  } = useThemeContext()

  return (
    <Container data-testid="warranty">
      <WarrantyCard>
        <PictoBigContainer>
          <Picto size={80} color={color} />
        </PictoBigContainer>
        <PictoSmallContainer>
          <Picto size={30} color={color} />
        </PictoSmallContainer>
        <WarrantyContainer>
          <Title data-testid="warranty_title">{title}</Title>
          <Description data-testid="warranty_description">{description}</Description>
        </WarrantyContainer>
      </WarrantyCard>
    </Container>
  )
}

Warranty.propTypes = {
  title: Proptypes.string.isRequired,
  description: Proptypes.string.isRequired,
  picto: Proptypes.any.isRequired,
}
