import React from "react"
import styled from "styled-components"
import Warranty from "./warranty.component"
import data from "./warranties.data"
import { useTranslation } from "../../translation/translate.component"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default function Warranties () {
  const { t } = useTranslation()

  return (
    <Wrapper>
      {data.warranties.map(({ title: warrantyTitle, description, picto }, i) => (
        <Warranty title={t(warrantyTitle)} description={t(description)} picto={picto} key={i} />
      ))}
    </Wrapper>
  )
}

Warranties.propTypes = {}
