import React from "react"
import PropTypes from "prop-types"
import Picto from "../picto.component"

export default function TwoHours (props) {
  return (
    <Picto viewBox="0 0 29.01 33.03" {...props}>
      <g>
        <rect transform="matrix(.5 -.87 .87 .5 1.44 21.69)" x="18.5" y="9.14" width="2.06" height=".91" />
        <rect x="13.75" y="7.14" width=".91" height="2.06" />
        <rect x="2.54" y="18.35" width="2.06" height=".91" />
        <rect transform="translate(-11.39 5.73) rotate(-30)" x="3.97" y="23.67" width="2.06" height=".91" />
        <rect transform="matrix(.5 -.87 .87 .5 -9.18 11.07)" x="4.54" y="12.46" width=".91" height="2.06" />
        <rect transform="translate(-19.82 21.71) rotate(-60)" x="7.86" y="27.57" width="2.06" height=".91" />
        <rect transform="translate(-3.6 5.72) rotate(-29.97)" x="8.43" y="8.56" width=".91" height="2.06" />
        <rect x="13.75" y="28.42" width=".91" height="2.06" />
        <rect transform="translate(-11.39 13.51) rotate(-29.98)" x="19.07" y="26.99" width=".91" height="2.06" />
        <rect transform="translate(-9.18 32.37) rotate(-60.03)" x="22.97" y="23.1" width=".91" height="2.06" />
        <rect x="23.82" y="18.35" width="2.06" height=".91" />
        <path d="M22.38,13.55l-8.94,3.3a2.12,2.12,0,0,0-.56.33A1.86,1.86,0,1,0,15.29,20h0l7.2-6.09.27.47,1.78-1-.45-.79-1.69,1Zm-7.83,5.59a.74.74,0,0,1-.89-.52.73.73,0,1,1,.89.52Z" />
        <path d="M26.9,8.62a.73.73,0,0,0,1,0l.86-.83a.73.73,0,0,0,0-1h0l-2-2.12a.73.73,0,0,0-1,0l-.86.83a.74.74,0,0,0,0,1h0l.25.27L23.64,8.19a14.2,14.2,0,0,0-8.33-3.55V3.11l1.48-.76A.41.41,0,0,0,17,2V.4a.37.37,0,0,0-.32-.4H11.75a.37.37,0,0,0-.32.4V2a.41.41,0,0,0,.2.37l1.48.76V4.64A14.17,14.17,0,0,0,4.78,8.19l-.91.91c-.16.15-.62.67-.62.67a14.21,14.21,0,1,0,21.92,0l1.47-1.42Zm-.32,10.19A12.38,12.38,0,1,1,14.21,6.43,12.37,12.37,0,0,1,26.58,18.81Z" />
      </g>
    </Picto>
  )
}

TwoHours.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
