import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Headset from "../../../components/pictos/headset.component"
import TwoHours from "../../../components/pictos/twoHours.component"
import Coin from "../../../components/pictos/coin.component"
import ThumpUp from "../../../components/pictos/thumpUp.component"
import breakpoint from "styled-components-breakpoint"

const ThumpUpWrapper = styled.div`
  width: ${props => props.size}px;
  position: relative;

  :after {
    color: ${props => props.color || `white`};
    content: "*";
    position: absolute;
    top: -5px;
    right: -5px;

    ${breakpoint(`small`)`
      top: 0;
      right: 0;
    `}
  }
`

function WarrantyThumpUp ({ size, color }) {
  return (
    <ThumpUpWrapper size={size} color={color}>
      <ThumpUp />
    </ThumpUpWrapper>
  )
}

WarrantyThumpUp.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

const warranties = [
  {
    title: `common:information.warranty_title1`,
    description: `common:information.warranty_description1`,
    mention: false,
    picto: Headset,
  },
  {
    title: `common:information.warranty_title2`,
    description: `common:information.warranty_description2`,
    mention: false,
    picto: TwoHours,
  },
  {
    title: `common:information.warranty_title3`,
    description: `common:information.warranty_description3`,
    mention: true,
    picto: Coin,
  },
]

APP_CONFIG.featureFlags.satisfactionKpi &&
  warranties.push({
    title: `common:information.warranty_title4`,
    description: `common:information.warranty_description4`,
    mention: false,
    picto: ThumpUp,
  })

const data = {
  warranties,
}

export default data
